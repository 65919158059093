import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
})
export class AddCampaignComponent implements OnInit {
  campaignForm: any;
  token: any;
  formData: FormData = new FormData();
  image: any = '../../../assets/images/upload-campaign-sample-img.png';
  submittedCampaignForm: boolean = false;
  isImageUploaded = false;
  custom_fields: any = [];
  constructor(
    private router: Router,
    private apiService: LoginService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.token = this.apiService.checkToken();
    this.campaignForm = this.formBuilder.group({
      campaign_name: ['', Validators.required],
      is_active: [false, Validators.required],
      campaign_message: ['', Validators.required],
      campaign_image: [''],
      custom_field_1: [null],
      custom_field_1_visible: [false],
      custom_field_1_type: ['string'],
      custom_field_2: [null],
      custom_field_2_visible: [false],
      custom_field_2_type: ['string'],
      custom_field_3: [null],
      custom_field_3_visible: [false],
      custom_field_3_type: ['string'],
      custom_field_4: [null],
      custom_field_4_visible: [false],
      custom_field_4_type: ['string'],
      custom_field_5: [null],
      custom_field_5_visible: [false],
      custom_field_5_type: ['string'],
      custom_field_6: [null],
      custom_field_6_visible: [false],
      custom_field_6_type: ['string'],
      custom_field_7: [null],
      custom_field_7_visible: [false],
      custom_field_7_type: ['string'],
      custom_field_8: [null],
      custom_field_8_visible: [false],
      custom_field_8_type: ['string'],
      custom_field_9: [null],
      custom_field_9_visible: [false],
      custom_field_9_type: ['string'],
      custom_field_10: [null],
      custom_field_10_visible: [false],
      custom_field_10_type: ['string'],
    });
  }
  handleFileInput(event: any) {
    if (event.target.files.length > 0) {
      this.isImageUploaded = true;
      const file = event.target.files[0];
      this.image = event.target.files[0].name;
      this.formData.append('file', file);
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.image = reader.result;
      };
    }
  }
  get campaignFormVal() {
    return this.campaignForm.controls;
  }
  onSubmitCampaignForm() {
    this.submittedCampaignForm = true;

    if (this.campaignForm.invalid) {
      return;
    } else {
      const result = Object.keys(this.campaignForm.value).filter(
        (e: any) => !e.endsWith('visible') && e.startsWith('custom') && !e.endsWith('type')
      );

      for (const allCustom of result) {
        let eachCustomField = this.campaignForm.value[allCustom];
        // if (eachCustomField?.toLowerCase() === 'dob') {
        //   this.custom_fields.push({
        //     field_name: eachCustomField,
        //     field_type: 'date',
        //     visibility: this.campaignForm.value[`${allCustom}_visible`],
        //   });
        // } else if (eachCustomField?.toLowerCase() === 'pincode') {
        //   this.custom_fields.push({
        //     field_name: eachCustomField,
        //     field_type: 'number',
        //     visibility: this.campaignForm.value[`${allCustom}_visible`],
        //   });
        // } else {
        if (eachCustomField) {
          this.custom_fields.push({
            field_name: eachCustomField,
            field_type: this.campaignForm.value[`${allCustom}_type`],
            visibility: this.campaignForm.value[`${allCustom}_visible`],
          });
        }
        //}
      }
      this.custom_fields = this.custom_fields.filter((e: any) => e.field_name);
      this.campaignForm.value.custom_fields = this.custom_fields;
      console.log("this.custom_field:::",this.custom_fields)
      if (this.campaignForm.value.custom_fields.length > 0) {
        alert(`Custom fields will not be editable once created and saved`);
      }
      this.apiService
        .addCampaign(this.token, this.campaignForm.value)
        .subscribe(
          (res: any) => {
            if (res['status'] === 'success') {
              if (this.isImageUploaded) {
                this.apiService
                  .addCampaignImage(this.token, this.formData, res['result'])
                  .subscribe(
                    (res: any) => {
                      if (res) {
                        this.toaster.success(
                          `Campaign Image Added with id ${res['result']}`
                        );
                        this.router.navigate(['/campaign']);
                      }
                    },
                    (err: any) => {
                      if (err['status'] == 400) {
                        this.toaster.error(err.error.message);
                      }
                    }
                  );
              }
              this.campaignForm.reset();
              this.campaignForm.controls['is_active'].setValue(false);
              this.custom_fields = [];
              this.submittedCampaignForm = false;
              this.toaster.success(`Campaign Got Added...`);
              this.router.navigate(['/campaign']);
            }else{
              const errMsg = res.error?.message || "Campaign Name Already Exist"; 
              this.campaignForm.controls['campaign_name'].setErrors({ customError: errMsg });
            }
          },
          (err: any) => {
            if (err['status'] == 400) {
              this.toaster.error(err.error.message);
              this.campaignForm.reset();
              this.campaignForm.controls['is_active'].setValue(false);
              this.custom_fields = [];
              this.submittedCampaignForm = false;
            }
          }
        );
    }
  }
  goToBack() {
    this.router.navigate(['/campaign']);
  }
  onlyChar(ev: any) {
    let onlynum = /^[a-zA-Z ]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }
  onlyAlphaNumeric(event: any) {
    let onlynum = /^[A-Za-z0-9\s]*$/;
    if (!onlynum.test(event.key)) {
      event.preventDefault();
    }
  }
}
